// ProjectTicketsCard.jsx
import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import moment from "moment";
import classnames from "classnames";

import { ProjectTicketModal } from "./ProjectTicketModal";
import { useFirebase } from "model/context/firebase.context";
import { COLLECTIONS } from "model/constants";

export function ProjectTicketsCard({
  projectId,
  projectName,
  tickets,
  setTickets,
  setError,
}) {
  const { db } = useFirebase();

  const [openModal, setModalOpen] = useState(false);
  const [editTicket, setEditTicket] = useState(null);
  const [activeTab, setActiveTab] = useState("1");

  // Toggle "checked" in DB via read-modify-write
  const handleToggleChecked = async (ticket) => {
    // local update
    const updated = { ...ticket, checked: !ticket.checked };
    setTickets((prev) => prev.map((t) => (t.id === ticket.id ? updated : t)));

    try {
      const projectSnap = await db.get(COLLECTIONS.projects, projectId);
      if (!projectSnap) throw new Error("Project not found");
      const projectPlain = JSON.parse(JSON.stringify(projectSnap));

      if (!projectPlain.tickets) {
        projectPlain.tickets = {};
      }

      // update the ticket's checked state
      projectPlain.tickets[ticket.id] = {
        ...(projectPlain.tickets[ticket.id] || {}),
        ...updated,
      };

      await db.update(COLLECTIONS.projects, projectId, projectPlain);
    } catch (e) {
      console.error("Failed to update ticket:", e);
      setError(e?.message || "Could not update the ticket");
    }
  };

  // Delete ticket in DB via read-modify-write
  const handleDeleteTicket = async (ticketId) => {
    // remove locally
    setTickets((prev) => prev.filter((t) => t.id !== ticketId));

    try {
      const projectSnap = await db.get(COLLECTIONS.projects, projectId);
      if (!projectSnap) throw new Error("Project not found");
      const projectPlain = JSON.parse(JSON.stringify(projectSnap));

      if (!projectPlain.tickets) {
        projectPlain.tickets = {};
      }

      // remove the ticket from the tickets object
      delete projectPlain.tickets[ticketId];

      await db.update(COLLECTIONS.projects, projectId, projectPlain);
    } catch (e) {
      console.error("Failed to delete ticket:", e);
      setError(e?.message || "Could not delete the ticket");
    }
  };

  const handleEditTicket = (ticket) => {
    setEditTicket(ticket);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEditTicket(null);
  };

  const uncheckedTickets = tickets.filter((t) => !t.checked);
  const checkedTickets = tickets.filter((t) => t.checked);

  const handleCopyTickets = () => {
    if (!uncheckedTickets.length) {
      setError("No unchecked tickets to copy.");
      return;
    }
    const headerLine = `Tickets for the project: ${
      projectName || "(Unknown Project)"
    }\n\n`;
    const textToCopy = uncheckedTickets
      .map((t, i) => {
        const date = moment(t.createdAt).format("MMM DD, YYYY");
        return `${i + 1}. ${t.name} | Description: ${
          t.description
        } | Priority: ${t.priority} | Created by: ${
          t.createdBy
        } | Date: ${date}`;
      })
      .join("\n");

    navigator.clipboard.writeText(headerLine + textToCopy).catch(() => {
      setError("Unable to copy tickets to clipboard.");
    });
  };

  return (
    <>
      <Card className="h-100 no-print">
        <CardHeader>
          <div className="d-flex">
            <h3>Tickets</h3>
            <Button
              size="sm"
              color="link"
              type="button"
              className="ml-auto text-nowrap"
              onClick={() => {
                setEditTicket(null);
                setModalOpen(true);
              }}
            >
              Add Ticket
            </Button>
            <Button
              size="sm"
              color="link"
              type="button"
              className="text-nowrap"
              onClick={handleCopyTickets}
              style={{ marginLeft: "1rem" }}
            >
              <i className="fa fa-clipboard" aria-hidden="true"></i>
            </Button>
          </div>

          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => setActiveTab("1")}
              >
                Unchecked
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => setActiveTab("2")}
              >
                Checked
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>

        <CardBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {uncheckedTickets.length ? (
                <Table className="align-items-center table-flush" responsive>
                  <tbody className="list">
                    {uncheckedTickets.map((ticket) => (
                      <tr key={ticket.id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={false}
                            onChange={() => handleToggleChecked(ticket)}
                          />
                        </td>
                        <td>
                          <p className="m-0 font-weight-bold">{ticket.name}</p>
                          <p className="m-0 small text-muted">
                            {ticket.description}
                          </p>
                          <p className="m-0 small text-right">
                            Priority: {ticket.priority}
                          </p>
                          <p className="m-0 small text-right">
                            Created by: {ticket.createdBy}
                          </p>
                          <p className="m-0 small text-right">
                            {moment(ticket.createdAt).format("MMM DD, YYYY")}
                          </p>
                        </td>
                        <td className="text-right">
                          <Button
                            size="sm"
                            color="secondary"
                            onClick={() => handleEditTicket(ticket)}
                          >
                            Edit
                          </Button>
                          <Button
                            size="sm"
                            color="danger"
                            className="ml-2"
                            onClick={() => handleDeleteTicket(ticket.id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p className="text-center">No unchecked tickets.</p>
              )}
            </TabPane>

            <TabPane tabId="2">
              {checkedTickets.length ? (
                <Table className="align-items-center table-flush" responsive>
                  <tbody className="list">
                    {checkedTickets.map((ticket) => (
                      <tr key={ticket.id}>
                        <td>
                          <input
                            type="checkbox"
                            checked
                            onChange={() => handleToggleChecked(ticket)}
                          />
                        </td>
                        <td>
                          <p className="m-0 font-weight-bold">{ticket.name}</p>
                          <p className="m-0 small text-muted">
                            {ticket.description}
                          </p>
                          <p className="m-0 small text-right">
                            Priority: {ticket.priority}
                          </p>
                          <p className="m-0 small text-right">
                            Created by: {ticket.createdBy}
                          </p>
                          <p className="m-0 small text-right">
                            {moment(ticket.createdAt).format("MMM DD, YYYY")}
                          </p>
                        </td>
                        <td className="text-right">
                          <Button
                            size="sm"
                            color="secondary"
                            onClick={() => handleEditTicket(ticket)}
                          >
                            Edit
                          </Button>
                          <Button
                            size="sm"
                            color="danger"
                            className="ml-2"
                            onClick={() => handleDeleteTicket(ticket.id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p className="text-center">No checked tickets.</p>
              )}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>

      <ProjectTicketModal
        projectId={projectId}
        openModal={openModal}
        setModalOpen={handleCloseModal}
        setError={setError}
        editTicket={editTicket}
      />
    </>
  );
}
