// ProjectTicketModal.jsx
import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
} from "reactstrap";
import { nanoid } from "nanoid";
import { useFirebase } from "model/context/firebase.context";
import { useAuth } from "model/context/auth.context";
import { COLLECTIONS } from "model/constants";

export function ProjectTicketModal({
  projectId,
  openModal,
  setModalOpen,
  setError,
  editTicket,
}) {
  const { session } = useAuth();
  const { db } = useFirebase();
  const [loading, setLoading] = useState(false);

  const [ticketData, setTicketData] = useState({
    name: "",
    description: "",
    priority: "Low",
  });

  useEffect(() => {
    if (editTicket) {
      setTicketData({
        name: editTicket.name,
        description: editTicket.description,
        priority: editTicket.priority,
      });
    } else {
      setTicketData({
        name: "",
        description: "",
        priority: "Low",
      });
    }
  }, [editTicket]);

  const onSubmitTicket = async () => {
    try {
      setLoading(true);
      if (!projectId) {
        setError("No project ID found.");
        return;
      }
      const projectSnap = await db.get(COLLECTIONS.projects, projectId);
      if (!projectSnap) {
        setError("Project not found.");
        return;
      }
      const projectPlain = JSON.parse(JSON.stringify(projectSnap));
      if (!projectPlain.tickets) {
        projectPlain.tickets = {};
      }
      if (editTicket) {
        const id = editTicket.id;
        projectPlain.tickets[id] = {
          ...(projectPlain.tickets[id] || {}),
          name: ticketData.name,
          description: ticketData.description,
          priority: ticketData.priority,
        };
      } else {
        const newId = nanoid(10);
        projectPlain.tickets[newId] = {
          name: ticketData.name,
          description: ticketData.description,
          priority: ticketData.priority,
          createdBy: session.user.displayName || session.user.email,
          createdAt: new Date().toISOString(),
          checked: false,
        };
      }
      await db.update(COLLECTIONS.projects, projectId, projectPlain);
      setModalOpen(false);
    } catch (err) {
      setError(err.message || "Error saving ticket.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={openModal} toggle={() => setModalOpen(false)}>
      <ModalHeader toggle={() => setModalOpen(false)}>
        {editTicket ? "Edit Ticket" : "Add Ticket"}
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Input
            required
            placeholder="Ticket Name"
            type="text"
            value={ticketData.name}
            onChange={(e) =>
              setTicketData((prev) => ({ ...prev, name: e.target.value }))
            }
          />
        </FormGroup>
        <FormGroup>
          <Input
            required
            placeholder="Ticket Description"
            type="textarea"
            rows={5}
            value={ticketData.description}
            onChange={(e) =>
              setTicketData((prev) => ({
                ...prev,
                description: e.target.value,
              }))
            }
          />
        </FormGroup>
        <FormGroup>
          <Input
            type="select"
            value={ticketData.priority}
            onChange={(e) =>
              setTicketData((prev) => ({ ...prev, priority: e.target.value }))
            }
          >
            <option>Low</option>
            <option>Medium</option>
            <option>High</option>
          </Input>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={() => setModalOpen(false)}>
          Cancel
        </Button>
        <Button color="danger" onClick={onSubmitTicket} disabled={loading}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
