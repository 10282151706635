import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  Input,
} from "reactstrap";
import { useFirebase } from "model/context/firebase.context";
import { COLLECTIONS } from "model/constants";

const TicketsOverview = () => {
  const [tickets, setTickets] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const { db } = useFirebase();

  useEffect(() => {
    // Listen for changes in the projectTickets collection
    const unsubscribeTickets = db.listen(
      COLLECTIONS.projectTickets,
      async (data) => {
        if (!data) {
          // If there's no data, just set an empty array
          setTickets([]);
          return;
        }

        // data is an object keyed by projectId
        const projectIds = Object.keys(data);
        const loadedTickets = [];

        // Loop through each projectId
        for (const projectId of projectIds) {
          const projectTickets = data[projectId];

          // Make sure the projectTickets value is valid
          if (
            !projectTickets ||
            typeof projectTickets !== "object" ||
            Array.isArray(projectTickets)
          ) {
            continue;
          }

          try {
            // Retrieve the project info
            const projectSnapshot = await db.get(
              `${COLLECTIONS.projects}/${projectId}`
            );
            const projectData = projectSnapshot?.val();
            const projectName = projectData?.name || "Unknown Project";

            // Convert the projectTickets object into an array of ticket objects
            for (const [ticketId, ticketValue] of Object.entries(
              projectTickets
            )) {
              loadedTickets.push({
                ...ticketValue,
                ticketId,
                projectId,
                projectName,
              });
            }
          } catch (error) {
            console.error(
              `Error fetching project data for projectId: ${projectId}`,
              error
            );
          }
        }

        setTickets(loadedTickets);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribeTickets();
  }, [db]);

  // Filter tickets by "checked" status if showAll is not selected
  const filteredTickets = showAll
    ? tickets
    : tickets.filter((ticket) => ticket.checked === false);

  return (
    <Container className="mt-4" fluid>
      <Row>
        <Col md="12">
          <Card className="shadow">
            <CardHeader>Tickets Overview</CardHeader>
            <CardBody>
              <div className="d-flex align-items-center mb-3">
                <Input
                  type="checkbox"
                  checked={showAll}
                  onChange={() => setShowAll(!showAll)}
                  className="me-2"
                />
                <span>Show All Tickets</span>
              </div>
              <Table responsive striped>
                <thead>
                  <tr>
                    <th>Project Name</th>
                    <th>Ticket ID</th>
                    <th>Title</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTickets.map((ticket, index) => (
                    <tr key={index}>
                      <td>{ticket.projectName}</td>
                      <td>{ticket.ticketId}</td>
                      <td>{ticket.name || "N/A"}</td>
                      <td>{ticket.checked ? "Checked" : "Unchecked"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TicketsOverview;
