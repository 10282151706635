import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
} from "reactstrap";
import { useFirebase } from "model/context/firebase.context";
import { useAuth } from "model/context/auth.context";

export function CustomerNoteModal({
  internal,
  openModal,
  setModalOpen,
  setError,
  entityId,
  entityType,
  newNoteNumber,
  existigNoteId,
}) {
  const { session } = useAuth(); // Get the current session
  const [loading, setLoading] = useState(false);
  const { db } = useFirebase();
  const [value, setValue] = useState("");

  useEffect(() => {
    if (existigNoteId) {
      // Editing an existing note => pre-fill
      setValue(existigNoteId.text || "");
    } else {
      // New note => clear
      setValue("");
    }
  }, [existigNoteId]);

  const onUploadFile = async () => {
    try {
      setLoading(true);
      if (value?.trim() && internal.current) {
        const entityNote = {
          text: value,
          userName: session.user.displayName || session.user.email,
          createdAt: new Date().toISOString(),
        };
        try {
          if (existigNoteId && typeof existigNoteId.key === "number") {
            // Update existing note at array index existigNoteId.key
            await db.update(
              `${entityType}/${entityId}/notes`,
              "" + existigNoteId.key,
              entityNote
            );
          } else {
            // Create new note at index = length of the array
            await db.update(
              `${entityType}/${entityId}/notes`,
              "" + newNoteNumber,
              entityNote
            );
          }
        } catch (e) {
          console.log("second try error", e);
        }
        setValue("");
        setModalOpen(false);
      } else {
        setError("Note is required");
      }
    } catch (e) {
      setError(e.message || "Cannot save the note due to an error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={openModal}
      toggle={() => {
        setModalOpen(false);
      }}
    >
      <ModalHeader>
        <h3>Add Note</h3>
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Input
            required
            name="text"
            className="form-control text-dark"
            placeholder="Type a note"
            type="textarea"
            rows={12}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          color="light"
          type="button"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          className="ml-4"
          color="danger"
          type="button"
          onClick={onUploadFile}
          disabled={loading}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
}
