/* eslint-disable react-hooks/exhaustive-deps */
import { LoaderSpinner } from "components/Loader/LoaderSpinner";
import { COLLECTIONS } from "model/constants";
import { useFirebase } from "model/context/firebase.context";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
  Modal,
  Row,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

export function CustomerModal({ isLead, open, onClose, selectedItem }) {
  const navigate = useNavigate();
  const confirmDeleteTimeout = useRef();
  const confirmPromotionTimeout = useRef();
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmPromote, setConfirmPromote] = useState(false);
  const [enableConfirmDelete, setEnableConfirmDelete] = useState(false);
  const [enableConfirmPromote, setEnableConfirmPromote] = useState(false);

  const [notes, setNotes] = useState(selectedItem?.notes || []);
  const { db } = useFirebase();
  const [nestedTab, setNestedTab] = useState("1");

  const [dropdownOriginValue, setDropdownOriginValue] = useState(
    selectedItem?.origin || "Select Lead Origin"
  );
  const [dropdownSalespersonValue, setDropdownSalespersonValue] = useState(
    selectedItem?.salesperson || "Select Responsible Salesperson"
  );

  // Additional states
  const [isVisited, setIsVisited] = useState(false);
  const [isbidSent, setbidSent] = useState(false);
  const [isInfoConfirmed, setisInfoConfirmed] = useState(false);
  const [requestedDoNotContact, setrequestedDoNotContact] = useState(false);
  const [hasBasementFinished, sethasBasementFinished] = useState(false);

  const [visitDate, setVisitDate] = useState("");
  const [bidNumber, setBidNumber] = useState("");
  const [bidDate, setBidDate] = useState("");
  const [bidValue, setBidValue] = useState("");

  // New fields
  const [isRejected, setIsRejected] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [reconnectBy, setReconnectBy] = useState("");
  const [observations, setObservations] = useState("");

  const handleDropdownOriginSelect = (e, value) => {
    e.preventDefault();
    setDropdownOriginValue(value);
  };

  const handleDropdownSalespersonSelect = (e, value) => {
    e.preventDefault();
    setDropdownSalespersonValue(value);
  };

  const config = isLead
    ? {
        formId: "#new-lead-form",
        collection: COLLECTIONS.leads,
        typeLabel: "Lead",
      }
    : {
        formId: "#new-customer-form",
        collection: COLLECTIONS.customers,
        typeLabel: "Customer",
      };

  const validateElement = (element) => {
    const { required, value, type, name } = element;
    if (name === "note" && !!value?.trim()) {
      // This line ensures the "note" field doesn't stay in an error state
      return "add note or delete content";
    } else if (required && !value) {
      return "required";
    } else if (
      type === "email" &&
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value)
    ) {
      return "invalid email";
    }
  };

  const onValidate = () => {
    const errors = {};
    const form = document.querySelector(config.formId);
    for (let element of form.elements) {
      const { type, name } = element;
      if (type === "button") continue;
      const error = validateElement(element);
      if (error) errors[name] = error;
    }
    return errors;
  };

  const onSubmit = async () => {
    const errors = onValidate();
    const values = {
      notes,
      isVisited,
      isbidSent,
      isInfoConfirmed,
      requestedDoNotContact,
      hasBasementFinished,
      origin: dropdownOriginValue,
      salesperson: dropdownSalespersonValue,
      // Additional new fields
      isRejected,
      rejectReason,
      reconnectBy,
      observations,
    };

    const form = document.querySelector(config.formId);
    for (let element of form.elements) {
      const { value, checked, type, name } = element;
      if (type === "button" || name === "note") continue;
      values[name] =
        type === "checkbox"
          ? checked
          : typeof value === "string"
          ? value.trim()
          : value;
    }

    if (Object.keys(errors).length) {
      setErrors(errors);
      setError("There are errors in the form.");
      console.log(errors);
    } else {
      setLoading(true);
      try {
        // If visit checkbox is checked, save visit info
        if (isVisited) {
          const visitData = {
            visitDate,
            customerId: selectedItem?.id, // undefined for new
            salesperson: dropdownSalespersonValue,
          };
          await db.insert("visits", visitData);
        }

        // If bid was sent, save bid info
        if (isbidSent) {
          const bidData = {
            bidNumber,
            bidDate,
            bidValue,
            customerId: selectedItem?.id, // undefined for new
          };
          await db.insert("bids-sent", bidData);
        }

        if (selectedItem) {
          await db.update(config.collection, selectedItem.id, values);
          onClose();
        } else {
          const insertedKey = await db.insert(config.collection, values);
          if (insertedKey) {
            onClose();
          } else {
            throw new Error("Record not generated");
          }
        }
      } catch (e) {
        setError(
          `${config.typeLabel} was not saved due to an error: ${
            e?.messasge || e
          }`
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const onDelete = async () => {
    setLoading(true);
    try {
      if (selectedItem) await db.delete(config.collection, selectedItem.id);
      onClose();
    } catch (e) {
      setError(
        `${config.typeLabel} was not deleted due to an error: ${
          e?.messasge || e
        }`
      );
    } finally {
      setLoading(false);
    }
  };

  const onPromote = async () => {
    setLoading(true);
    try {
      const { id, ...values } = selectedItem;
      const insertedKey = await db.insert(COLLECTIONS.customers, values);
      if (insertedKey) {
        await onDelete();
        navigate(`/admin/contacts/customers?lead=${insertedKey}`, {
          replace: true,
        });
      } else {
        throw new Error("Record not generated");
      }
    } catch (e) {
      setError(
        `${config.typeLabel} was not saved due to an error: ${e?.messasge || e}`
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setErrors({});
      setError("");
      setTab(0);
      setConfirmDelete(false);
      setConfirmPromote(false);
      setEnableConfirmDelete(false);
      setEnableConfirmPromote(false);
      setNotes([]);
      clearTimeout(confirmDeleteTimeout.current);
      clearTimeout(confirmPromotionTimeout.current);

      if (selectedItem) {
        setDropdownOriginValue(selectedItem.origin || "Select Lead Origin");
        setDropdownSalespersonValue(
          selectedItem.salesperson || "Select Responsible Salesperson"
        );
      }
    } else {
      setNotes(selectedItem?.notes || []);
    }
  }, [open]);

  useEffect(() => {
    if (selectedItem) {
      setDropdownOriginValue(selectedItem.origin || "Select Lead Origin");
      setDropdownSalespersonValue(
        selectedItem.salesperson || "Select Responsible Salesperson"
      );
      setBidNumber(selectedItem.bidNumber || "");
      setBidDate(selectedItem.bidDate || "");
      setBidValue(selectedItem.bidValue || "");
      setVisitDate(selectedItem.visitDate || "");
      setIsVisited(selectedItem.isVisited || false);
      setisInfoConfirmed(selectedItem.isInfoConfirmed || false);
      setbidSent(selectedItem.isbidSent || false);
      setrequestedDoNotContact(selectedItem.requestedDoNotContact || false);
      sethasBasementFinished(selectedItem.hasBasementFinished || false);

      // New fields
      setIsRejected(selectedItem.isRejected || false);
      setRejectReason(selectedItem.rejectReason || "");
      setReconnectBy(selectedItem.reconnectBy || "");
      setObservations(selectedItem.observations || "");
    } else {
      // Reset for new
      setDropdownOriginValue("Select Lead Origin");
      setDropdownSalespersonValue("Select Responsible Salesperson");
      setBidNumber("");
      setBidDate("");
      setBidValue("");
      setVisitDate("");
      setIsVisited(false);
      setisInfoConfirmed(false);
      setbidSent(false);
      setrequestedDoNotContact(false);
      sethasBasementFinished(false);

      // Reset new fields
      setIsRejected(false);
      setRejectReason("");
      setReconnectBy("");
      setObservations("");
      setNotes([]);
    }
  }, [selectedItem]);

  useEffect(() => {
    setError("");
  }, [tab]);

  const renderNotes = notes
    .sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1))
    .map((note) => (
      <ListGroupItem key={note.createdAt}>
        <div className="text-light text-xs float-right">
          {new Date(note.createdAt).toDateString()}
        </div>
        <div
          className="pt-4"
          dangerouslySetInnerHTML={{
            __html: note.value ? note.value.replace(/\r|\n/gi, "<br/>") : "",
          }}
        />
      </ListGroupItem>
    ));

  return (
    <Modal isOpen={open} className="modal-dialog-centered modal-white modal-lg">
      <div className="p-3">
        <div
          className="modal-body"
          style={{
            minHeight: 450,
          }}
        >
          <form
            id={config.formId.replace("#", "")}
            autoComplete="off"
            className="h-100"
            onChange={(e) => {
              const error = validateElement(e.target);
              const name = e.target.name;
              if (name !== "note" || (name === "note" && !error))
                setErrors({ ...errors, [name]: error });
            }}
          >
            <div className="d-md-flex flex-row justify-content-between align-items-start mb-5">
              <h3 className="text-muted font-weight-bold">
                {tab === 0
                  ? "Basic Information"
                  : tab === 1
                  ? "Address"
                  : tab === 2
                  ? "Visit & Bid"
                  : "Notes"}
              </h3>
              {!!selectedItem && (
                <div className="d-flex flex-flow justify-content-between">
                  {(confirmDelete || confirmPromote) && (
                    <Button
                      size="sm"
                      color="light"
                      type="button"
                      onClick={() => {
                        setConfirmDelete(false);
                        setConfirmPromote(false);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  {!confirmPromote && (
                    <Button
                      size="sm"
                      className="ml-4"
                      color="danger"
                      type="button"
                      disabled={confirmDelete && !enableConfirmDelete}
                      onClick={() => {
                        if (confirmDelete) {
                          return onDelete();
                        }
                        clearTimeout(confirmDeleteTimeout.current);
                        setEnableConfirmDelete(false);
                        confirmDeleteTimeout.current = setTimeout(() => {
                          setEnableConfirmDelete(true);
                        }, 2000);
                        setConfirmDelete(!confirmDelete);
                      }}
                    >
                      {confirmDelete
                        ? "Confirm Delete"
                        : `Delete ${config.typeLabel}`}
                      {confirmDelete && !enableConfirmDelete && (
                        <Spinner size="sm" className="ml-2" />
                      )}
                    </Button>
                  )}
                  {isLead && !confirmDelete && (
                    <Button
                      size="sm"
                      className="ml-4"
                      color="info"
                      type="button"
                      disabled={confirmPromote && !enableConfirmPromote}
                      onClick={() => {
                        if (confirmPromote) {
                          return onPromote();
                        }
                        clearTimeout(confirmPromotionTimeout.current);
                        setEnableConfirmPromote(false);
                        confirmPromotionTimeout.current = setTimeout(() => {
                          setEnableConfirmPromote(true);
                        }, 2000);
                        setConfirmPromote(!confirmDelete);
                      }}
                    >
                      {confirmPromote
                        ? "Confirm Promotion"
                        : "Promote to Customer"}
                      {confirmPromote && !enableConfirmPromote && (
                        <Spinner size="sm" className="ml-2" />
                      )}
                    </Button>
                  )}
                </div>
              )}
            </div>

            {/* TAB 0: Basic Information */}
            <Row className={tab !== 0 ? "d-none" : ""}>
              <Col md="6">
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-control-label font-weight-light">
                        Name
                        <sup className={!!errors?.name ? "text-danger" : ""}>
                          * {errors.name}
                        </sup>
                      </label>
                      <Input
                        required
                        name="name"
                        className={`form-control text-dark ${
                          errors?.name ? "is-invalid" : ""
                        }`}
                        placeholder="Name"
                        type="text"
                        defaultValue={selectedItem?.name}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-control-label font-weight-light">
                        Last Name
                        <sup
                          className={!!errors?.lastName ? "text-danger" : ""}
                        >
                          * {errors.lastName}
                        </sup>
                      </label>
                      <Input
                        required
                        name="lastName"
                        className={`form-control text-dark ${
                          errors?.lastName ? "is-invalid" : ""
                        }`}
                        placeholder="Last Name"
                        type="text"
                        defaultValue={selectedItem?.lastName}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <label className="form-control-label font-weight-light">
                    Email Address
                    <sup className={!!errors?.email ? "text-danger" : ""}>
                      * {errors.email}
                    </sup>
                  </label>
                  <Input
                    required
                    name="email"
                    className={`form-control text-dark ${
                      errors?.email ? "is-invalid" : ""
                    }`}
                    placeholder="Email Address"
                    type="email"
                    defaultValue={selectedItem?.email}
                  />
                </FormGroup>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label font-weight-light">
                        Phone (Mobile)
                        <sup className={!!errors?.mobile ? "text-danger" : ""}>
                          * {errors.mobile}
                        </sup>
                      </label>
                      <Input
                        required
                        name="mobile"
                        className={`form-control text-dark ${
                          errors?.mobile ? "is-invalid" : ""
                        }`}
                        placeholder="Phone (Mobile)"
                        type="tel"
                        defaultValue={selectedItem?.mobile}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label font-weight-light">
                        Phone (Other)
                      </label>
                      <Input
                        name="phone"
                        className={`form-control text-dark ${
                          errors?.phone ? "is-invalid" : ""
                        }`}
                        placeholder="Phone (Other)"
                        type="tel"
                        defaultValue={selectedItem?.phone}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>

              <Col md="6">
                <FormGroup>
                  <label className="form-control-label font-weight-light">
                    Origin
                  </label>
                  <br />
                  <Input
                    hidden
                    name="origin"
                    className={`form-control text-dark ${
                      errors?.origin ? "is-invalid" : ""
                    }`}
                    placeholder="Origin"
                    value={dropdownOriginValue}
                    readOnly
                  />
                  <UncontrolledDropdown>
                    <DropdownToggle caret color="primary">
                      {dropdownOriginValue}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        href="#"
                        onClick={(e) =>
                          handleDropdownOriginSelect(e, "Joist Migration")
                        }
                      >
                        Joist Migration
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) =>
                          handleDropdownOriginSelect(e, "Passive Called/texted")
                        }
                      >
                        Passive Called/texted
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) =>
                          handleDropdownOriginSelect(
                            e,
                            "Contacted us on Website"
                          )
                        }
                      >
                        Contacted us on Website
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) =>
                          handleDropdownOriginSelect(
                            e,
                            "Contacted us on Social Media"
                          )
                        }
                      >
                        Contacted us on Social Media
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) =>
                          handleDropdownOriginSelect(
                            e,
                            "Reference from another client"
                          )
                        }
                      >
                        Reference from another client
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) =>
                          handleDropdownOriginSelect(e, "LandRercords Scrap")
                        }
                      >
                        LandRercords Scrap
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => handleDropdownOriginSelect(e, "Other")}
                      >
                        Other
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label font-weight-light">
                    Salesperson
                  </label>
                  <br />
                  <Input
                    hidden
                    name="salesperson"
                    className={`form-control text-dark ${
                      errors?.salesperson ? "is-invalid" : ""
                    }`}
                    placeholder="Salesperson"
                    value={dropdownSalespersonValue}
                    readOnly
                  />
                  <UncontrolledDropdown>
                    <DropdownToggle caret color="primary">
                      {dropdownSalespersonValue}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        href="#"
                        onClick={(e) =>
                          handleDropdownSalespersonSelect(e, "Vinny Maia")
                        }
                      >
                        Vinny Maia
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) =>
                          handleDropdownSalespersonSelect(e, "Lucas Conde")
                        }
                      >
                        Lucas Conde
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </FormGroup>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <div className="d-flex align-items-center">
                        <small className="form-control-label font-weight-light mr-3">
                          Correct contact info?
                        </small>
                        <div>
                          <label className="custom-toggle custom-toggle">
                            <input
                              type="checkbox"
                              name="isInfoConfirmed"
                              checked={isInfoConfirmed}
                              onChange={(e) =>
                                setisInfoConfirmed(e.target.checked)
                              }
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="No"
                              data-label-on="Yes"
                            />
                          </label>
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <div className="d-flex align-items-center">
                        <small className="form-control-label font-weight-light mr-3">
                          Do Not Contact?
                        </small>
                        <div>
                          <label className="custom-toggle custom-toggle">
                            <input
                              type="checkbox"
                              name="requestedDoNotContact"
                              checked={requestedDoNotContact}
                              onChange={(e) =>
                                setrequestedDoNotContact(e.target.checked)
                              }
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="No"
                              data-label-on="Yes"
                            />
                          </label>
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* TAB 1: Address */}
            <Row className={tab !== 1 ? "d-none h-75" : "h-75"}>
              <Nav tabs className="nav-fill flex-column flex-sm-row">
                <NavItem>
                  <NavLink
                    className={nestedTab === "1" ? "active" : ""}
                    onClick={() => setNestedTab("1")}
                  >
                    Address
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={nestedTab === "2" ? "active" : ""}
                    onClick={() => setNestedTab("2")}
                  >
                    Property Information
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={nestedTab === "3" ? "active" : ""}
                    onClick={() => setNestedTab("3")}
                  >
                    Zillow Info
                  </NavLink>
                </NavItem>
              </Nav>

              <Col md="12">
                <TabContent activeTab={nestedTab}>
                  <TabPane tabId="1">
                    <h3>Address</h3>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            Address
                            <sup
                              className={!!errors?.address ? "text-danger" : ""}
                            >
                              * {errors.address}
                            </sup>
                          </label>
                          <Input
                            required
                            name="address"
                            className={`form-control text-dark ${
                              errors?.address ? "is-invalid" : ""
                            }`}
                            placeholder="Address"
                            type="text"
                            defaultValue={selectedItem?.address}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            Address 2
                          </label>
                          <Input
                            name="address2"
                            className={`form-control text-dark ${
                              errors?.address2 ? "is-invalid" : ""
                            }`}
                            placeholder="Address 2"
                            type="text"
                            defaultValue={selectedItem?.address2}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            City
                            <sup
                              className={!!errors?.city ? "text-danger" : ""}
                            >
                              * {errors.city}
                            </sup>
                          </label>
                          <Input
                            required
                            name="city"
                            className={`form-control text-dark ${
                              errors?.city ? "is-invalid" : ""
                            }`}
                            placeholder="City"
                            type="text"
                            defaultValue={selectedItem?.city}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            State
                            <sup
                              className={!!errors?.state ? "text-danger" : ""}
                            >
                              * {errors.state}
                            </sup>
                          </label>
                          <Input
                            required
                            name="state"
                            className={`form-control text-dark ${
                              errors?.state ? "is-invalid" : ""
                            }`}
                            placeholder="State"
                            type="text"
                            defaultValue={selectedItem?.state}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            Zip / Postal Code
                            <sup
                              className={!!errors?.zipCode ? "text-danger" : ""}
                            >
                              * {errors.zipCode}
                            </sup>
                          </label>
                          <Input
                            required
                            name="zipCode"
                            className={`form-control text-dark ${
                              errors?.zipCode ? "is-invalid" : ""
                            }`}
                            placeholder="Zip / Postal Code"
                            type="text"
                            defaultValue={selectedItem?.zipCode}
                          />
                        </FormGroup>

                        <Row md="12">
                          <FormGroup>
                            <label className="form-control-label font-weight-light">
                              Latitude
                              <sup
                                className={
                                  !!errors?.latitude ? "text-danger" : ""
                                }
                              >
                                * {errors.latitude}
                              </sup>
                            </label>
                            <Input
                              required
                              name="latitude"
                              className={`form-control text-dark ${
                                errors?.latitude ? "is-invalid" : ""
                              }`}
                              placeholder="Latitude"
                              type="text"
                              defaultValue={selectedItem?.latitude}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label className="form-control-label font-weight-light">
                              Longitude
                              <sup
                                className={
                                  !!errors?.longitude ? "text-danger" : ""
                                }
                              >
                                * {errors.longitude}
                              </sup>
                            </label>
                            <Input
                              required
                              name="longitude"
                              className={`form-control text-dark ${
                                errors?.longitude ? "is-invalid" : ""
                              }`}
                              placeholder="Longitude"
                              type="text"
                              defaultValue={selectedItem?.longitude}
                            />
                          </FormGroup>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId="2">
                    <h3>Property Information</h3>
                    <Row>
                      <Col md="6">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="d-flex align-items-center">
                                <small className="form-control-label font-weight-light mr-3">
                                  Basement Finished?
                                </small>
                                <div>
                                  <label className="custom-toggle custom-toggle">
                                    <input
                                      type="checkbox"
                                      name="hasBasementFinished"
                                      checked={hasBasementFinished}
                                      onChange={(e) =>
                                        sethasBasementFinished(e.target.checked)
                                      }
                                    />
                                    <span
                                      className="custom-toggle-slider rounded-circle"
                                      data-label-off="No"
                                      data-label-on="Yes"
                                    />
                                  </label>
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label className="form-control-label font-weight-light">
                                Source
                              </label>
                              <Input
                                name="hasBasementFinishedSource"
                                className={`form-control text-dark ${
                                  errors?.hasBasementFinishedSource
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Address 2"
                                type="text"
                                defaultValue={
                                  selectedItem?.hasBasementFinishedSource
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            Acreage
                          </label>
                          <Input
                            name="acreage"
                            className={`form-control text-dark ${
                              errors?.acreage ? "is-invalid" : ""
                            }`}
                            placeholder="Acreage"
                            type="number"
                            defaultValue={selectedItem?.acreage}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            Parcel Number
                          </label>
                          <Input
                            name="parcelNumber"
                            className="form-control text-dark"
                            placeholder="Parcel Number"
                            type="text"
                            defaultValue={selectedItem?.parcelNumber}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            Ceiling Height(ft)
                          </label>
                          <Input
                            name="ceilingHeight"
                            className="form-control text-dark"
                            placeholder="Ceiling Height"
                            type="text"
                            defaultValue={selectedItem?.ceilingHeight}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            2019 Value
                          </label>
                          <Input
                            name="year2019value"
                            className={`form-control text-dark ${
                              errors?.year2019value ? "is-invalid" : ""
                            }`}
                            placeholder="year 2019 value"
                            type="number"
                            defaultValue={selectedItem?.year2019value}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            2020 Value
                          </label>
                          <Input
                            name="year2020value"
                            className={`form-control text-dark ${
                              errors?.year2020value ? "is-invalid" : ""
                            }`}
                            placeholder="year 2020 value"
                            type="number"
                            defaultValue={selectedItem?.year2020value}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            2021 Value
                          </label>
                          <Input
                            name="year2021value"
                            className={`form-control text-dark ${
                              errors?.year2021value ? "is-invalid" : ""
                            }`}
                            placeholder="year 2021 value"
                            type="number"
                            defaultValue={selectedItem?.year2021value}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            2022 Value
                          </label>
                          <Input
                            name="year2022value"
                            className={`form-control text-dark ${
                              errors?.year2022value ? "is-invalid" : ""
                            }`}
                            placeholder="year 2022 value"
                            type="number"
                            defaultValue={selectedItem?.year2022value}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            2023 Value
                          </label>
                          <Input
                            name="year2023value"
                            className={`form-control text-dark ${
                              errors?.year2023value ? "is-invalid" : ""
                            }`}
                            placeholder="year 2023 value"
                            type="number"
                            defaultValue={selectedItem?.year2023value}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            2024 Value
                          </label>
                          <Input
                            name="year2024value"
                            className={`form-control text-dark ${
                              errors?.year2024value ? "is-invalid" : ""
                            }`}
                            placeholder="year 2024 value"
                            type="number"
                            defaultValue={selectedItem?.year2024value}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId="3">
                    <h3>Zillow Info</h3>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            Zillow Listing Link
                          </label>
                          <Input
                            name="zillowLink"
                            className="form-control text-dark"
                            placeholder="Zillow Listing Link"
                            type="url"
                            defaultValue={selectedItem?.zillowLink}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            Zillow Last Listing Date
                          </label>
                          <Input
                            name="zillowLastListingDate"
                            className="form-control text-dark"
                            placeholder="Zillow Last Listing Date"
                            type="date"
                            defaultValue={selectedItem?.zillowLastListingDate}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            Zillow Last Value
                          </label>
                          <Input
                            name="zillowValue"
                            className="form-control text-dark"
                            placeholder="Zillow Last Value"
                            type="number"
                            defaultValue={selectedItem?.zillowValue}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            Year Built
                          </label>
                          <Input
                            name="yearBuilt"
                            className="form-control text-dark"
                            placeholder="Year Built"
                            type="number"
                            defaultValue={selectedItem?.yearBuilt}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            Zillow Acreage
                          </label>
                          <Input
                            name="zillowAcreage"
                            className="form-control text-dark"
                            placeholder="Zillow Acreage"
                            type="number"
                            defaultValue={selectedItem?.zillowAcreage}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            $/SQFT
                          </label>
                          <Input
                            name="pricePerSqft"
                            className="form-control text-dark"
                            placeholder="$/SQFT"
                            type="number"
                            defaultValue={selectedItem?.pricePerSqft}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            HOA Monthly Fee Estimated
                          </label>
                          <Input
                            name="hoaFee"
                            className="form-control text-dark"
                            placeholder="HOA Fee Estimated"
                            type="number"
                            defaultValue={selectedItem?.hoaFee}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            What's Special
                          </label>
                          <Input
                            name="special"
                            className="form-control text-dark"
                            placeholder="What's Special"
                            type="textarea"
                            defaultValue={selectedItem?.special}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            Zillow Description
                          </label>
                          <Input
                            name="zillowDescription"
                            className="form-control text-dark"
                            placeholder="Zillow Description"
                            type="textarea"
                            defaultValue={selectedItem?.zillowDescription}
                          />
                        </FormGroup>
                        <FormGroup>
                          <div className="d-flex align-items-center">
                            <small className="form-control-label font-weight-light mr-3">
                              Basement?
                            </small>
                            <div>
                              <label className="custom-toggle custom-toggle">
                                <input
                                  defaultChecked={
                                    selectedItem
                                      ? !!selectedItem.hasBasement
                                      : true
                                  }
                                  type="checkbox"
                                  name="hasBasement"
                                />
                                <span
                                  className="custom-toggle-slider rounded-circle"
                                  data-label-off="No"
                                  data-label-on="Yes"
                                />
                              </label>
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <div className="d-flex align-items-center">
                            <small className="form-control-label font-weight-light mr-3">
                              Separated Entrance for Basement
                            </small>
                            <div>
                              <label className="custom-toggle custom-toggle">
                                <input
                                  defaultChecked={
                                    selectedItem
                                      ? !!selectedItem.hasBasementEntrance
                                      : true
                                  }
                                  type="checkbox"
                                  name="hasBasementEntrance"
                                />
                                <span
                                  className="custom-toggle-slider rounded-circle"
                                  data-label-off="No"
                                  data-label-on="Yes"
                                />
                              </label>
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            Total Structure Area
                          </label>
                          <Input
                            name="structureArea"
                            className="form-control text-dark"
                            placeholder="Total Structure Area"
                            type="number"
                            defaultValue={selectedItem?.structureArea}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            Total Interior Livable Area
                          </label>
                          <Input
                            name="livableArea"
                            className="form-control text-dark"
                            placeholder="Total Interior Livable Area"
                            type="number"
                            defaultValue={selectedItem?.livableArea}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            Finished Area Above Ground
                          </label>
                          <Input
                            name="finishedArea"
                            className="form-control text-dark"
                            placeholder="Finished Area Above Ground"
                            type="number"
                            defaultValue={selectedItem?.finishedArea}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            Stories
                          </label>
                          <Input
                            name="stories"
                            className="form-control text-dark"
                            placeholder="Stories"
                            type="number"
                            defaultValue={selectedItem?.stories}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className="form-control-label font-weight-light">
                            Zoning Description
                          </label>
                          <Input
                            name="zoningDescription"
                            className="form-control text-dark"
                            placeholder="Zoning Description"
                            type="textarea"
                            defaultValue={selectedItem?.zoningDescription}
                          />
                        </FormGroup>
                        <FormGroup>
                          <div className="d-flex align-items-center">
                            <small className="form-control-label font-weight-light mr-3">
                              Private Notes
                            </small>
                            <div>
                              <label className="custom-toggle custom-toggle">
                                <input
                                  defaultChecked={
                                    selectedItem
                                      ? !!selectedItem.privateNotes
                                      : true
                                  }
                                  type="checkbox"
                                  name="privateNotes"
                                />
                                <span
                                  className="custom-toggle-slider rounded-circle"
                                  data-label-off="No"
                                  data-label-on="Yes"
                                />
                              </label>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>

            {/* TAB 2: Visit & Bid ( + Rejection / Observations ) */}
            {/* TAB 2: Visit & Bid (+ Rejection / Observations) */}
            <Row className={tab !== 2 ? "d-none h-75" : "h-75"}>
              <Col md="12" className="h-100">
                <FormGroup className="h-100">
                  <label className="form-control-label font-weight-light">
                    Visit and Bid
                  </label>

                  {/* Visited Checkbox and Date of Visit */}
                  <div className="d-flex align-items-center mb-3">
                    <small className="form-control-label font-weight-light mr-3">
                      Visited?
                    </small>
                    <label className="custom-toggle custom-toggle">
                      <input
                        type="checkbox"
                        name="isVisited"
                        checked={isVisited}
                        onChange={(e) => setIsVisited(e.target.checked)}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                      />
                    </label>
                    <Input
                      name="visitDate"
                      type="date"
                      className="ml-3"
                      placeholder="Date of Visit"
                      value={visitDate}
                      onChange={(e) => setVisitDate(e.target.value)}
                    />
                  </div>

                  {/* Bid Sent Checkbox and Bid Information */}
                  <div className="d-flex align-items-center mb-3">
                    <small className="form-control-label font-weight-light mr-3">
                      Bid Sent?
                    </small>
                    <label
                      className="custom-toggle custom-toggle"
                      style={{ marginRight: "40px" }}
                    >
                      <input
                        type="checkbox"
                        name="isbidSent"
                        checked={isbidSent}
                        onChange={(e) => setbidSent(e.target.checked)}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                      />
                    </label>

                    <Input
                      name="bidNumber"
                      type="number"
                      className="ml-3"
                      placeholder="Bid Number"
                      value={bidNumber}
                      onChange={(e) => setBidNumber(e.target.value)}
                    />

                    <Input
                      name="bidDate"
                      type="date"
                      className="ml-3"
                      placeholder="Date Bid Sent"
                      value={bidDate}
                      onChange={(e) => setBidDate(e.target.value)}
                    />

                    <Input
                      name="bidValue"
                      type="number"
                      className="ml-3"
                      placeholder="Bid Value"
                      value={bidValue}
                      onChange={(e) => setBidValue(e.target.value)}
                    />
                  </div>

                  {/* Rejected Checkbox + Reason (only visible if "Rejected?" is true) */}
                  <div className="d-flex align-items-center mb-3">
                    <small className="form-control-label font-weight-light mr-3">
                      Rejected?
                    </small>
                    <label className="custom-toggle custom-toggle">
                      <input
                        type="checkbox"
                        name="isRejected"
                        checked={isRejected}
                        onChange={(e) => setIsRejected(e.target.checked)}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                      />
                    </label>

                    {/* Show Reason ONLY if "Rejected?" is true */}
                    {isRejected && (
                      <FormGroup className="d-flex align-items-center">
                        <label className="form-control-label font-weight-light">
                          Reason:
                        </label>
                        <Input
                          type="select"
                          name="rejectReason"
                          className="form-control text-dark"
                          value={rejectReason}
                          onChange={(e) => setRejectReason(e.target.value)}
                        >
                          <option value="">-- Select Reason --</option>
                          <option>Chose someone else - price</option>
                          <option>Chose someone else - Timeline</option>
                          <option>Chose someone else - Referal</option>
                          <option>Chose someone else - Something Else</option>
                          <option>Didn't Clarify</option>
                          <option>Other</option>
                          <option>Decided to do it themselves</option>
                          <option>Paused</option>
                        </Input>
                      </FormGroup>
                    )}
                  </div>

                  {/* Re-connect By and Observations (ALWAYS shown) */}
                  <FormGroup>
                    <label className="form-control-label font-weight-light">
                      Re-connect by
                    </label>
                    <Input
                      type="date"
                      name="reconnectBy"
                      className="form-control text-dark"
                      value={reconnectBy}
                      onChange={(e) => setReconnectBy(e.target.value)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <label className="form-control-label font-weight-light">
                      Observations
                    </label>
                    <Input
                      type="textarea"
                      name="observations"
                      className="form-control text-dark"
                      placeholder="Add any observations here..."
                      value={observations}
                      onChange={(e) => setObservations(e.target.value)}
                    />
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>

            {/* TAB 3: Notes */}
            <Row className={tab !== 3 ? "d-none h-75" : "h-75"}>
              <Col md="6" className="h-100">
                <FormGroup className="h-100">
                  <div className="d-flex flex-row justify-content-between">
                    <label className="form-control-label font-weight-light">
                      New Note{" "}
                      <sup className={!!errors?.note ? "text-danger" : ""}>
                        {errors.note}
                      </sup>
                    </label>
                    <Button
                      size="sm"
                      color="link"
                      type="button"
                      onClick={() => {
                        const form = document.querySelector(config.formId);
                        const value = form.elements["note"].value;
                        if (value) {
                          form.elements["note"].value = "";
                          setNotes([
                            ...notes,
                            {
                              createdAt: new Date().toISOString(),
                              value,
                            },
                          ]);
                        }
                      }}
                    >
                      Add Note
                    </Button>
                  </div>
                  <Input
                    name="note"
                    className={`form-control text-dark h-100 ${
                      errors?.note ? "is-invalid" : ""
                    }`}
                    placeholder="Note won't be added until the 'Add Note' button is clicked."
                    type="textarea"
                    style={{
                      minHeight: 270,
                      resize: "none",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <ListGroup flush className="pre-scrollable d-none d-md-block">
                  {renderNotes}
                </ListGroup>
                <ListGroup flush className="d-md-none d-block">
                  {renderNotes}
                </ListGroup>
              </Col>
            </Row>
          </form>
          {!!error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
        </div>
        <div className="modal-footer">
          <Button color="link" type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="ml-auto"
            color="dark"
            type="button"
            onClick={() => setTab(tab - 1 <= 0 ? 0 : tab - 1)}
          >
            <i className="ni ni-bold-left" />
          </Button>
          {tab < 3 ? (
            <Button
              className="ml-1"
              color="dark"
              type="button"
              onClick={() => setTab(tab + 1)}
            >
              <i className="ni ni-bold-right" />
            </Button>
          ) : (
            <Button
              className="ml-1"
              color={!!error ? "danger" : "info"}
              type="button"
              onClick={onSubmit}
            >
              <i className="ni ni-check-bold" />
            </Button>
          )}
        </div>
      </div>
      {loading && <LoaderSpinner />}
    </Modal>
  );
}
