// File: views/pages/analytics/Analytics.js

import classnames from "classnames";
import { useRole } from "hooks/useRole";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import ProjectAnalytics from "./components/ProjectAnalytics";
import SalesPipeline from "./components/SalesPipeline";
import TicketsOverview from "./components/TicketsOverview";

const Analytics = () => {
  const [activeTab, setActiveTab] = useState("1");
  const navigate = useNavigate();
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { isProtected, hasAccess } = useRole();

  useEffect(() => {
    if (isProtected && !hasAccess) {
      navigate("/");
    }
  }, [isProtected, hasAccess, navigate]);

  return (
    <Container className="mt-4" fluid>
      {/* Navigation Tabs */}
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggle("1")}
          >
            Project Analytics
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggle("2")}
          >
            Sales Pipeline
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => toggle("3")}
          >
            Tickets Overview
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <ProjectAnalytics />
        </TabPane>
        <TabPane tabId="2">
          <SalesPipeline />
        </TabPane>
        <TabPane tabId="3">
          <TicketsOverview />
        </TabPane>
      </TabContent>
    </Container>
  );
};

export default Analytics;
