import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Container, Row, Col } from "reactstrap";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useFirebase } from "model/context/firebase.context";
import { COLLECTIONS } from "model/constants";
import { CustomerModal } from "components/Contacts/CustomerModal"; // <-- Import your modal

const ItemType = "CARD";

const DraggableCard = ({ lead, columnId, moveCard, onCardClick }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemType,
    item: { lead, columnId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  // IMPORTANT: Add onClick handler to open the modal
  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
        marginBottom: "10px",
      }}
      onClick={() => onCardClick(lead)} // Single click to open
    >
      <Card className="pipeline-card" style={{ padding: "10px" }}>
        <CardBody>
          <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            {lead.name} {lead.lastName}
          </div>
          <div style={{ fontSize: "0.9rem", color: "gray" }}>{lead.phone}</div>
          <div style={{ fontSize: "0.9rem", color: "gray" }}>
            {lead.address}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const DroppableColumn = ({ id, title, leads, moveCard, onCardClick }) => {
  const [, drop] = useDrop(() => ({
    accept: ItemType,
    drop: (item) => {
      if (item.columnId !== id) {
        moveCard(item.lead, item.columnId, id);
      }
    },
  }));

  return (
    <div ref={drop} style={{ minHeight: "300px", padding: "10px" }}>
      <Card className="shadow">
        <CardHeader>
          {title} ({leads.length})
        </CardHeader>
        <CardBody>
          {leads.map((lead) => (
            <DraggableCard
              key={lead.id}
              lead={lead}
              columnId={id}
              moveCard={moveCard}
              onCardClick={onCardClick} // Pass click handler down
            />
          ))}
        </CardBody>
      </Card>
    </div>
  );
};

const SalesPipeline = () => {
  const { db } = useFirebase();

  // 1. Track columns
  const [columns, setColumns] = useState({
    1: { id: "1", title: "Information Confirmed", leads: [] },
    2: { id: "2", title: "Visited", leads: [] },
    3: { id: "3", title: "Bid Sent", leads: [] },
    4: { id: "4", title: "Reconnect", leads: [] },
    5: { id: "5", title: "Win", leads: [] },
    6: { id: "6", title: "Rejected", leads: [] },
  });

  // 2. Track selected lead & modal state
  const [selectedLead, setSelectedLead] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 3. Handler to open modal with correct lead
  const handleCardClick = (lead) => {
    setSelectedLead(lead);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const unsubscribe = db.listen(COLLECTIONS.leads, (data) => {
      if (data) {
        const confirmedLeads = [];
        const visitedLeads = [];
        const bidSentLeads = [];
        const reconnectLeads = [];
        const winLeads = [];
        const rejectedLeads = [];

        Object.entries(data).forEach(([key, lead]) => {
          const leadWithId = { id: key, ...lead };

          // Distribute leads into columns:
          if (lead.isRejected) {
            rejectedLeads.push(leadWithId);
          } else if (lead.isInfoConfirmed && !lead.isVisited) {
            confirmedLeads.push(leadWithId);
          } else if (lead.isVisited && !lead.isbidSent) {
            visitedLeads.push(leadWithId);
          } else if (lead.isbidSent) {
            bidSentLeads.push(leadWithId);
          }
          // If you have logic for reconnect/win, add it:
          // else if (lead.isReconnect) { reconnectLeads.push(leadWithId); }
          // else if (lead.isWin) { winLeads.push(leadWithId); }
        });

        setColumns((prev) => ({
          ...prev,
          1: { ...prev["1"], leads: confirmedLeads },
          2: { ...prev["2"], leads: visitedLeads },
          3: { ...prev["3"], leads: bidSentLeads },
          4: { ...prev["4"], leads: reconnectLeads },
          5: { ...prev["5"], leads: winLeads },
          6: { ...prev["6"], leads: rejectedLeads },
        }));
      } else {
        // Reset if no data
        setColumns((prev) => ({
          ...prev,
          1: { ...prev["1"], leads: [] },
          2: { ...prev["2"], leads: [] },
          3: { ...prev["3"], leads: [] },
          4: { ...prev["4"], leads: [] },
          5: { ...prev["5"], leads: [] },
          6: { ...prev["6"], leads: [] },
        }));
      }
    });

    return () => {
      unsubscribe();
    };
  }, [db]);

  const moveCard = (lead, sourceColumnId, targetColumnId) => {
    setColumns((prevColumns) => {
      const sourceColumn = prevColumns[sourceColumnId];
      const targetColumn = prevColumns[targetColumnId];

      const updatedSourceLeads = sourceColumn.leads.filter(
        (l) => l.id !== lead.id
      );
      const updatedTargetLeads = [...targetColumn.leads, lead];

      return {
        ...prevColumns,
        [sourceColumnId]: { ...sourceColumn, leads: updatedSourceLeads },
        [targetColumnId]: { ...targetColumn, leads: updatedTargetLeads },
      };
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Container className="mt-4" fluid>
        <Row>
          {Object.values(columns).map((column) => (
            <Col key={column.id} md="2">
              <DroppableColumn
                id={column.id}
                title={column.title}
                leads={column.leads}
                moveCard={moveCard}
                onCardClick={handleCardClick}
              />
            </Col>
          ))}
        </Row>
      </Container>

      {/* 4. Render the CustomerModal with the selectedLead */}
      <CustomerModal
        isLead
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        selectedItem={selectedLead}
      />
    </DndProvider>
  );
};

export default SalesPipeline;
