import React, { useEffect, useState } from "react";
import {
  Table,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useFirebase } from "model/context/firebase.context";
import { COLLECTIONS } from "model/constants";
import { ProjectTransactionsModal } from "components/Project/ProjectTransactionsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const ProjectAnalytics = () => {
  const { db } = useFirebase();
  const [projects, setProjects] = useState([]);
  const [showOtherStatuses, setShowOtherStatuses] = useState(false);
  const [filterType, setFilterType] = useState("all");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const unsubscribeProjects = db.listen(COLLECTIONS.projects, (data) => {
      const transformedProjects = Object.entries(data || {}).map(
        ([id, project]) => {
          return {
            id,
            ...project,
            transactions: project.transactions
              ? Object.entries(project.transactions).map(
                  ([transactionId, transaction]) => ({
                    ...transaction,
                    id: transactionId, // Ensure the transaction ID is passed correctly
                    projectId: id, // Attach projectId for each transaction
                  })
                )
              : [],
          };
        }
      );
      setProjects(transformedProjects);
    });

    return () => unsubscribeProjects();
  }, [db]);

  const generateWeeks = (startDate, weeksCount) => {
    const weeks = [];
    const start = new Date(startDate);
    start.setDate(start.getDate() - ((start.getDay() + 6) % 7)); // Adjust start to the nearest Monday
    for (let i = 0; i < weeksCount; i++) {
      const weekStart = new Date(start);
      weekStart.setDate(start.getDate() + i * 7);
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekStart.getDate() + 6);
      weeks.push({ start: weekStart, end: weekEnd });
    }
    return weeks;
  };

  const weeks = generateWeeks("2024-12-30", 12);

  const filteredProjects = projects.filter((project) => {
    if (!showOtherStatuses && project.status !== "work-in-progress") {
      return false;
    }
    return true;
  });

  const getTransactionsForWeek = (transactions, week, receiveOrPay) => {
    if (!Array.isArray(transactions)) {
      return [];
    }

    const start = week.start.getTime();
    const end = week.end.getTime();

    return transactions.filter((t) => {
      const date = new Date(t.transactionDate).getTime();
      return date >= start && date <= end && t.receiveOrPay === receiveOrPay;
    });
  };

  const renderTransactionCell = (transactions, week, receiveOrPay) => {
    const transactionsForWeek = getTransactionsForWeek(
      transactions,
      week,
      receiveOrPay
    );

    return transactionsForWeek.map((transaction, index) => (
      <div
        key={index}
        style={{
          color: transaction.paid ? "grey" : receiveOrPay ? "green" : "red",
          fontWeight: "bold",
          marginBottom: "5px",
        }}
      >
        ${parseFloat(transaction.transactionValue).toFixed(2)}
        <FontAwesomeIcon
          icon={faEdit}
          style={{ cursor: "pointer", marginLeft: "5px" }}
          onClick={() => {
            setSelectedTransaction(transaction); // Correctly pass the transaction
            setEditMode(true);
            setModalOpen(true);
          }}
        />
      </div>
    ));
  };

  return (
    <>
      <Row className="mb-3">
        <Col md="6">
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={showOtherStatuses}
                onChange={(e) => setShowOtherStatuses(e.target.checked)}
              />{" "}
              Show Projects with Other Statuses
            </Label>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label for="filterType">Filter By:</Label>
            <Input
              type="select"
              id="filterType"
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
            >
              <option value="all">All</option>
              <option value="trades">Trades Schedule</option>
              <option value="payments">Payments</option>
              <option value="expenses">Expenses</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card className="shadow">
            <CardHeader>Project Analytics</CardHeader>
            <CardBody>
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th>Project</th>
                    {weeks.map((week, index) => (
                      <th
                        key={index}
                      >{`${week.start.toLocaleDateString()} - ${week.end.toLocaleDateString()}`}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredProjects.map((project) => (
                    <React.Fragment key={project.id}>
                      <tr>
                        <td>{project.name} - Schedule</td>
                        {weeks.map((_, index) => (
                          <td key={index}>
                            {project.trades?.[index]?.phase || "-"}
                          </td>
                        ))}
                      </tr>
                      {(filterType === "all" || filterType === "payments") && (
                        <>
                          <tr>
                            <td>{project.name} - Payments Received</td>
                            {weeks.map((week, index) => (
                              <td key={index}>
                                {renderTransactionCell(
                                  project.transactions,
                                  week,
                                  true
                                )}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td>{project.name} - Payments Made</td>
                            {weeks.map((week, index) => (
                              <td key={index}>
                                {renderTransactionCell(
                                  project.transactions,
                                  week,
                                  false
                                )}
                              </td>
                            ))}
                          </tr>
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ProjectTransactionsModal
        openModal={modalOpen}
        setModalOpen={setModalOpen}
        transaction={selectedTransaction}
        editMode={editMode}
        setEditMode={setEditMode}
        setSelectedTransaction={setSelectedTransaction}
        projectId={selectedTransaction?.projectId}
        internal={{ current: selectedTransaction?.id }} // Correctly pass the transaction ID
        setError={(message) => {
          console.error("Transaction Error:", message);
        }}
      />
    </>
  );
};

export default ProjectAnalytics;
