/* eslint-disable react-hooks/exhaustive-deps */
import { LoaderSpinner } from "components/Loader/LoaderSpinner";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import {
  get,
  getDatabase,
  onValue,
  push,
  ref,
  remove,
  set,
  update,
} from "firebase/database";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import { config } from "model/config";
import { createContext, useContext, useEffect, useState } from "react";

const FirebaseContext = createContext();

export const FirebaseProvider = ({ children }) => {
  const firebaseConfig = {
    apiKey: config.firebaseApiKey,
    authDomain: config.firebaseAuthDomain,
    projectId: config.firebaseProjectId,
    storageBucket: config.firebaseStorageBucket,
    messagingSenderId: config.firebaseMessagingSenderId,
    appId: config.firebaseAppId,
    measurementId: config.firebaseMeasurementId,
  };
  const [firebase, setFirebase] = useState(null);

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const provider = new GoogleAuthProvider();
    provider.addScope(config.firebaseGoogleProviderScope);
    if (!config.development) {
      provider.setCustomParameters({
        scope: "email",
        hd: config.domain,
      });
    }
    const db = getDatabase(app);
    const storage = getStorage(app);
    setFirebase({
      app,
      auth: getAuth(app),
      googleAuthProvider: provider,
      analytics: getAnalytics(app),
      db: {
        instance: db,
        insert: async (path, data) => {
          console.log("Insert called with path:", path, "and data:", data);
          const dbRef = ref(db, path);
          console.log("dbRef created:", dbRef);
          const itemRef = push(dbRef);
          console.log("itemRef created:", itemRef);
          await set(itemRef, {
            ...data,
            createdAt: new Date().toISOString(),
          });
          console.log("Data set, itemRef.key:", itemRef.key);
          if (!itemRef.key) {
            console.error("Failed to generate a key for the new item");
            throw new Error("Failed to generate a key for the new item");
          }
          return itemRef.key;
        },
        insertWithKey: async (path, key, data) => {
          console.log(
            "Insert with key called with path:",
            path,
            "key:",
            key,
            "and data:",
            data
          );
          const dbRef = ref(db, `${path}/${key}`);
          console.log("dbRef created:", dbRef);
          await set(dbRef, {
            ...data,
            createdAt: new Date().toISOString(),
          });
          console.log("Data set at key:", key);
          return key;
        },
        update: async (path, id, data) => {
          await update(ref(db, `${path}/${id}`), {
            ...data,
            updatedAt: new Date().toISOString(),
          });
        },
        listen: (path, onChange) => {
          return onValue(ref(db, path), (snapshot) => {
            const data = snapshot.val();
            onChange(data);
          });
        },
        delete: async (path, id) => {
          await remove(ref(db, `${path}/${id}`));
        },
        push: async (path, data) => {
          await set(ref(db, path), data);
        },
        get: async (path, id) => {
          const result = await get(ref(db, `${path}/${id}`));
          return result;
        },
        exists: async (path, id) => {
          if (id === undefined) {
            return false;
          }
          const snapshot = await get(ref(db, `${path}/${id}`));
          return snapshot.exists();
        },
      },
      storage: {
        instance: storage,
        upload: async (file, path = "") => {
          const fileRef = storageRef(
            storage,
            `${path ? `${path}/` : ""}${file.name}`
          );
          const result = await uploadBytes(fileRef, file);
          return result;
        },
        get: async (path, fname = "") => {
          const listRef = storageRef(
            storage,
            `${path}${fname ? `/${fname}` : ""}`
          );
          const result = await listAll(listRef);
          return result;
        },
        download: async (path, fname = "") => {
          const fileRef = storageRef(
            storage,
            `${path}${fname ? `/${fname}` : ""}`
          );
          const url = await getDownloadURL(fileRef);
          window.open(url, "_blank");
          return url;
        },
        delete: async (path, fname = "") => {
          const fileRef = storageRef(
            storage,
            `${path}${fname ? `/${fname}` : ""}`
          );
          await deleteObject(fileRef);
          return true;
        },
      },
    });
  }, []);

  return (
    <FirebaseContext.Provider value={firebase}>
      {!!firebase ? children : <LoaderSpinner />}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => useContext(FirebaseContext);
