export const COLLECTIONS = {
  leads: "/leads",
  customers: "/customers",
  subcontractors: "/subcontractors",
  suppliers: "/suppliers",
  materials: "/materials",
  projects: "/projects",
  projectComments: "/project-comments",
  activities: "/activities",
  trades: "/trades",
  projectTrades: "/project-trades",
  customerNotes: "/customer-notes",
  projectNotes: "/project-notes",
  projectTickets: "/project-tickets",
  users: "/users",
};

export const ROLES = {
  ADMIN: "admin",
  SALES: "sales",
  CONTRACTOR: "contractor",
  USER: "user", // Default role
};
