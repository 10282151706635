/* eslint-disable react-hooks/exhaustive-deps */
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useRole } from "hooks/useRole";
import { COLLECTIONS, ROLES } from "model/constants";
import { useFirebase } from "model/context/firebase.context";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import {
  Card,
  CardBody,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Row,
  Spinner,
} from "reactstrap";
import slugify from "slugify";

function RolesView() {
  const { db } = useFirebase();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { role } = useRole();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [looadingUpdateIndex, setLoadingUpdateIndex] = useState(-1);

  useEffect(() => {
    const unsubscribe = db.listen(COLLECTIONS.users, (data) => {
      if (data) {
        const keys = Object.keys(data);
        const users = keys.reduce((result, key) => {
          const item = data[key];
          result.push({
            ...item,
            id: key,
          });
          return result;
        }, []);
        setUsers(users);
      } else {
        setUsers([]);
      }
      if (loading) setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const onChangeRole = async (index, selectedUser, selectedRole) => {
    if (role !== ROLES.ADMIN) {
      return navigate("/");
    }

    setLoadingUpdateIndex(index);
    try {
      await Promise.all([
        db.update(COLLECTIONS.users, selectedUser.id, { role: selectedRole }),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUpdateIndex(-1);
    }
  };

  const filteredusers = users.filter((item) => {
    const opts = { lower: true, replacement: " " };
    const name = slugify(item.name, opts);
    if (search) {
      return name.includes(search);
    }
    return true;
  });

  const rolesOptions = useMemo(() => Object.values(ROLES), [ROLES]);

  return (
    <>
      <SimpleHeader search={setSearch} />
      <Container className="mt--6" fluid>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <ListGroup flush>
                  <ListGroupItemHeading>
                    <Row>
                      <Col lg="3" md="6" sm="6" xs="10">
                        Name
                      </Col>
                      <Col lg="3" md="5" sm="4" className="d-none d-sm-block">
                        Email
                      </Col>
                      <Col lg="2" className="d-none d-lg-block">
                        Role
                      </Col>
                      <Col lg="1" md="1" sm="2" xs="2"></Col>
                    </Row>
                  </ListGroupItemHeading>
                  {!users.length && (
                    <ListGroupItem>
                      <p className="text-center">
                        {loading ? "Loading..." : "There are no users yet"}
                      </p>
                    </ListGroupItem>
                  )}
                  {filteredusers
                    .sort((a, b) =>
                      new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
                    )
                    .map((user, userIndex) => (
                      <ListGroupItem key={user.id} action>
                        <Row className="align-users-center">
                          <Col
                            lg="3"
                            md="6"
                            sm="6"
                            xs="10"
                            className="text-bold text-nowrap text-truncate"
                          >
                            {user.name}
                            <br />
                          </Col>
                          <Col
                            lg="3"
                            md="5"
                            sm="4"
                            className="d-none d-sm-block text-nowrap text-truncate"
                          >
                            {user.email}
                          </Col>
                          <Col
                            lg="2"
                            className="d-none d-lg-block text-nowrap text-truncate"
                          >
                            <Select2
                              className="form-control"
                              defaultValue={user.role}
                              options={{
                                placeholder: "Select",
                              }}
                              onChange={(e) =>
                                onChangeRole(userIndex, user, e.target.value)
                              }
                              data={rolesOptions.map((_role, index) => ({
                                id: rolesOptions[index],
                                text: rolesOptions[index],
                              }))}
                            />
                          </Col>
                          <Col
                            lg="2"
                            className="d-none d-lg-block text-nowrap text-truncate"
                          >
                            {looadingUpdateIndex === userIndex && (
                              <Spinner size="sm" className="ml-2" />
                            )}
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default RolesView;
