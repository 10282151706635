import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Col,
  Row,
} from "reactstrap";
import { ProjectTransactionsModal } from "./ProjectTransactionsModal";
import { useFirebase } from "model/context/firebase.context";
import { COLLECTIONS } from "model/constants";

export function ProjectTransactionsCard({
  internal,
  transactions = {},
  projectId,
  setError,
}) {
  const [openModal, setModalOpen] = useState(false);

  // Add these state variables inside the component
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [editMode, setEditMode] = useState(false);

  // Add this to get access to the database
  const { db } = useFirebase();

  // Add these handler functions inside the component
  const handleEditTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    setEditMode(true);
    setModalOpen(true);
  };

  const handleDeleteTransaction = async (transaction) => {
    if (window.confirm("Are you sure you want to delete this transaction?")) {
      try {
        await db.delete(
          `${COLLECTIONS.projects}/${projectId}/transactions`,
          transaction.id
        );
      } catch (error) {
        setError(`Error deleting transaction: ${error.message || error}`);
      }
    }
  };

  // Convert the transactions object to an array
  const transactionArray = transactions
    ? Object.keys(transactions).map((key) => ({
        id: key,
        ...transactions[key],
      }))
    : [];

  return (
    <>
      <Card className="h-100 no-print">
        <CardHeader>
          <div className="d-flex">
            <h3>Transactions</h3>
            <Button
              disabled={!projectId}
              size="sm"
              color="link"
              type="button"
              className="ml-auto text-nowrap"
              onClick={() => {
                setModalOpen(true);
              }}
            >
              Log Transaction
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          {transactionArray.length > 0 ? (
            <ListGroup flush>
              <ListGroupItemHeading>
                <Row>
                  <Col lg="5" md="6" sm="6" xs="10">
                    Memo
                  </Col>
                  <Col lg="3" className="d-none d-lg-block">
                    Value
                  </Col>
                  <Col lg="2" className="d-none d-lg-block">
                    Date
                  </Col>
                  <Col lg="1" md="1" sm="2" xs="2"></Col>
                </Row>
              </ListGroupItemHeading>
              {transactionArray.map((transaction) => (
                <ListGroupItem key={transaction.id}>
                  <Row>
                    <Col
                      lg="5"
                      md="6"
                      sm="6"
                      xs="10"
                      className="text-bold text-nowrap text-truncate"
                    >
                      {transaction.transactionMemo}
                    </Col>
                    <Col
                      lg="2"
                      className="d-none d-lg-block text-nowrap text-truncate"
                      style={{
                        color: transaction.receiveOrPay ? "green" : "red",
                      }}
                    >
                      ${transaction.transactionValue}
                    </Col>
                    <Col
                      lg="2"
                      className="d-none d-lg-block text-nowrap text-truncate"
                    >
                      {transaction.transactionDate}
                    </Col>
                    <Col
                      lg="3"
                      md="4"
                      sm="6"
                      xs="12"
                      className="d-flex flex-row flex-nowrap"
                    >
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => handleEditTransaction(transaction)}
                      >
                        Edit
                      </Button>
                      <Button
                        size="sm"
                        color="danger"
                        className="ml-2"
                        onClick={() => handleDeleteTransaction(transaction)}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : (
            <p className="text-center">There're no Transactions.</p>
          )}
        </CardBody>
      </Card>
      <ProjectTransactionsModal
        projectId={projectId}
        internal={internal}
        openModal={openModal}
        setModalOpen={setModalOpen}
        setError={setError}
        transaction={selectedTransaction} // Add this line
        editMode={editMode} // Add this line
        setEditMode={setEditMode} // Add this line
        setSelectedTransaction={setSelectedTransaction} // Add this line
      />
    </>
  );
}
