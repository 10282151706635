import { AuthContext } from "model/context/auth.context";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import routes from "../routes";
import { getFlatRoutes, getPathRouteIndex } from "../utils";

/**
 * Custom hook to check if the current route is protected and if the user has the required permissions.
 *
 * @returns {Object} - Returns an object with isProtected and hasAccess properties.
 * @property {boolean} isProtected - Indicates if the current route is protected.
 * @property {boolean} hasAccess - Indicates if the user has access to the current route.
 */

export const useRole = () => {
  const { userRole } = useContext(AuthContext); // Get the user's role from the AuthProvider

  const location = useLocation(); // Get the current location object
  const flatedRoutes = getFlatRoutes(routes); // Flatten the nested routes array

  const routeIndex = getPathRouteIndex(location.pathname, flatedRoutes); // Find the index of the current route
  const currentRoute = routeIndex !== -1 ? flatedRoutes[routeIndex] : null; // Get the current route object if found

  if (currentRoute) {
    const { isProtected } = currentRoute; // Check if the current route is protected
    const hasAccess = isProtected
      ? currentRoute.permissions.includes(userRole) // Check if the user has the required permissions
      : true; // If the route is not protected, the user has access
    return { isProtected, hasAccess, role: userRole };
  }

  // If no matching route is found, return default values
  return { role: userRole, isProtected: false, hasAccess: true };
};
